import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";

function VisionRotationMesh({ children }) {
  const mesh = useRef();

  useFrame(
    (state, delta) => (
      (mesh.current.rotation.y += 0.005),
      (mesh.current.rotation.x = 10),
      (mesh.current.rotation.z = 50)
    )
  );

  return <mesh ref={mesh}>{children}</mesh>;
}

export default VisionRotationMesh;
