import React, { useRef, useLayoutEffect } from "react";
import { Canvas } from "@react-three/fiber";
import VisionRotationMesh from "../reusable/VisionRotationMesh";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import gsap from "gsap";

import {
  technoOrange,
  h2Styles,
  visionGrey,
  rightAlignStyles,
  pStyles,
  taglineStyles,
  glitch,
  relative,
} from "../styles/global.module.css";
import {
  ateamStyles,
  globeStyles,
  divP,
  divTagline,
} from "../styles/auxiliary-teams.module.css";

function AuxiliaryTeams({ getStandAloneTimelines }) {
  const tlAuxiliaryTeams = useRef();
  const breakpoints = useBreakpoint();
  tlAuxiliaryTeams.current = gsap.timeline();

  useLayoutEffect(() => {
    let parentElement = document.getElementById("auxiliary-teams");
    let children = parentElement.childNodes;
    tlAuxiliaryTeams.current.from(children, {
      duration: 1,
      opacity: 0,
      stagger: { each: 0.2 },
    });
    getStandAloneTimelines(tlAuxiliaryTeams.current, "tl-auxiliary-teams");

    return function cleanupListener() {
      try {
        tlAuxiliaryTeams.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);
  return (
    <section
      id="auxiliary-teams"
      className={`section ${rightAlignStyles} ${ateamStyles}`}
    >
      <h2
        data-heading="Auxiliary Teams"
        className={`${glitch} ${relative} ${h2Styles} ${rightAlignStyles}`}
      >
        Auxiliary Teams <br />[<span className={technoOrange}>A</span>-
        <span className={technoOrange}>Teams</span>]
      </h2>
      <div className={`${divTagline}`}>
        <p className={`${visionGrey} ${taglineStyles} ${rightAlignStyles}`}>
          We're digital experts
          <span className={technoOrange}>,</span>
        </p>
        <p className={`${visionGrey} ${taglineStyles} ${rightAlignStyles}`}>
          networked with digital experts
          <span className={technoOrange}>.</span>
        </p>
      </div>
      {breakpoints.xxs ? (
        <div className={`${divP}`}>
          <p className={`${pStyles} ${rightAlignStyles}`}>
            We're an opinionated team of
            <span className={technoOrange}>
              {" "}
              location and technology agnostic Netizens{" "}
            </span>
            (citizens of the Net). We believe that quality requires time and the
            right amount of effort, so we aim to find harmony between both. As
            the saying goes "Rome wasn't built in a day", neither is your
            digital empire.
          </p>
          <p className={`${pStyles} ${rightAlignStyles}`}>
            Our goal is never to make our favorite technologies fit your
            challenge, but to objectively use the
            <span className={technoOrange}>
              {" "}
              best-in-class technologies
            </span>{" "}
            that the solution requires. We black box each project and decide
            which programming languages, infrastructure requirements and media
            channels are best suited, then assemble your A-Team for guaranteed
            success.
          </p>
        </div>
      ) : (
        <div className={`${divP}`}>
          <p className={`${pStyles} ${rightAlignStyles}`}>
            We're an opinionated team of
            <span className={technoOrange}>
              {" "}
              location and technology agnostic Netizens{" "}
            </span>
            (citizens of the Net). We believe that quality requires time and the
            right amount of effort, so we aim to find harmony between both. As
            the saying goes "Rome wasn't built in a day", neither is your
            digital empire.
          </p>
          <p className={`${pStyles} ${rightAlignStyles}`}>
            We implement our Auxiliary Team (A-Team) strategy for all projects.
            Custom teams are formed specifically for different phases of your
            project. This allows us to reduce overall cost while
            <span className={technoOrange}> maximizing project efficiency</span>
            , ensuring that only the expert resources required for the current
            phase are working on your project at any given time.
          </p>
          <p className={`${pStyles} ${rightAlignStyles}`}>
            Our goal is never to make our favorite technologies fit your
            challenge, but to objectively use the
            <span className={technoOrange}>
              {" "}
              best-in-class technologies
            </span>{" "}
            that the solution requires. We black box each project and decide
            which programming languages, infrastructure requirements and media
            channels are best suited, then assemble your A-Team for guaranteed
            success.
          </p>
        </div>
      )}
      <div id="globes" className={globeStyles}>
        {breakpoints.sm ? (
          <figure>
            <img src="/globe.svg" alt="Globe" />
          </figure>
        ) : (
          <Canvas camera={{ position: [0, 0, 6], fov: 45, near: 1, far: 4000 }}>
            <ambientLight intensity={0.1} />
            <directionalLight position={[0, 0, 5]} />
            <VisionRotationMesh>
              <sphereBufferGeometry args={[1.5, 30, 15]} />
              <meshPhongMaterial attach="material" color="#fc4d0d" wireframe />
            </VisionRotationMesh>
          </Canvas>
        )}
      </div>
    </section>
  );
}

export default AuxiliaryTeams;
