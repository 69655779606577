// extracted by mini-css-extract-plugin
export var workflowStyles = "workflow-module--workflowStyles--TZtBt";
export var workflowH2Styles = "workflow-module--workflowH2Styles--Uln2o";
export var workflowDescriptionStyles = "workflow-module--workflowDescriptionStyles--uMjul";
export var description1Styles = "workflow-module--description1Styles--KGrUJ";
export var description2Styles = "workflow-module--description2Styles--exxej";
export var description3Styles = "workflow-module--description3Styles--PRnNx";
export var description4Styles = "workflow-module--description4Styles--u659z";
export var description5Styles = "workflow-module--description5Styles--cC6Wg";
export var description6Styles = "workflow-module--description6Styles--KVxwT";
export var glitchStyles = "workflow-module--glitchStyles--dtgbI";
export var glitchStylesVideo = "workflow-module--glitchStylesVideo--YbzhA";
export var glitchStylesText = "workflow-module--glitchStylesText--jUOxU";
export var heptagonStyles = "workflow-module--heptagonStyles--y6iQ+";
export var polygonStyles = "workflow-module--polygonStyles--bf4nn";
export var listStyles = "workflow-module--listStyles--9rV59";
export var numberStyles = "workflow-module--numberStyles--+CASO";
export var titleStyles = "workflow-module--titleStyles--i9kxZ";
export var titlePStyles = "workflow-module--titlePStyles--5Hoa1";
export var iconStyles = "workflow-module--iconStyles--Pt0v5";
export var icon0Styles = "workflow-module--icon0Styles---E87w";
export var icon1Styles = "workflow-module--icon1Styles--KakhR";
export var icon2Styles = "workflow-module--icon2Styles--2L5Zp";
export var icon3Styles = "workflow-module--icon3Styles--GN1LU";
export var icon4Styles = "workflow-module--icon4Styles--Cisik";
export var icon5Styles = "workflow-module--icon5Styles--d2nXX";
export var icon6Styles = "workflow-module--icon6Styles--P2TW7";