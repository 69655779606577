// React
import * as React from "react";
import { useRef, useLayoutEffect } from "react";

// CSS
import "normalize.css";
import styled from "styled-components";

// GSAP
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Meta Information
import SEO from "../components/reusable/Seo";
import { Helmet } from "react-helmet";

// Components: HTML Structure
import VisionMatrix from "../components/structure/VisionMatrix";
import Layout from "../components/structure/Layout";
import MainContent from "../components/structure/MainContent";
import Sidebar from "../components/structure/Sidebar";
import Branding from "../components/structure/Branding";
import Footer from "../components/structure/Footer";
import ScrollCue from "../components/reusable/ScrollCue";
import LetsBuild from "../components/reusable/LetsBuild";

// Components: Sections
import HelloFriend from "../components/hello/HelloFriend";
import DigitalPartner from "../components/hello/DigitalPartner";
import Workflow from "../components/hello/Workflow";
import AuxiliaryTeams from "../components/hello/AuxiliaryTeams";

const Container = styled.div`
  display: grid;
  grid-template-areas: "vision";
`;

gsap.registerPlugin(ScrollTrigger);

const IndexPage = () => {
  const tlHello = useRef();
  const tlDigitalPartner = useRef();
  const tlWorkflow = useRef();
  const tlAuxiliaryTeams = useRef();
  const tlLetsBuild = useRef();
  const tlMatrix = useRef();
  const tlMatrixBackground = useRef();
  const tlScrollDown = useRef();
  const tlSidebar = useRef();
  const tlBranding = useRef();

  tlHello.current = gsap.timeline();
  tlDigitalPartner.current = gsap.timeline();
  tlWorkflow.current = gsap.timeline();
  tlAuxiliaryTeams.current = gsap.timeline();
  tlLetsBuild.current = gsap.timeline();
  tlMatrix.current = gsap.timeline();
  tlMatrixBackground.current = gsap.timeline();
  tlScrollDown.current = gsap.timeline();
  tlSidebar.current = gsap.timeline();
  tlBranding.current = gsap.timeline();

  function getStandAloneTimelines(tl, name) {
    switch (name) {
      case "tl-hello-friend":
        tlHello.current.add(tl, name);
        break;
      case "tl-digital-partner":
        tlDigitalPartner.current.add(tl, name);
        break;
      case "tl-workflow":
        tlWorkflow.current.add(tl, name);
        break;
      case "tl-auxiliary-teams":
        tlAuxiliaryTeams.current.add(tl, name);
        break;
      case "tl-lets-build":
        tlLetsBuild.current.add(tl, name);
        break;
      case "tl-matrix":
        tlMatrix.current.add(tl, name);
        break;
      case "tl-matrix-background":
        tlMatrixBackground.current.add(tl, name + `+=5.5`);
        break;
      case "tl-scroll":
        tlScrollDown.current.add(tl, name + `+=7.4`);
        break;
      case "tl-sidebar":
        tlSidebar.current.add(tl, name);
        tlSidebar.current.play(2);
        break;
      case "tl-branding":
        tlBranding.current.add(tl, name + `+=5.5`);
        break;
    }
  }

  useLayoutEffect(() => {
    gsap.utils.toArray(".section").forEach((section, i) => {
      ScrollTrigger.create({
        trigger: section,
        start: "top 30%",
        toggleActions: "restart none none none",
        // markers: true,
        animation: tlMatrix.current,
      });
    });
    ScrollTrigger.create({
      trigger: "#hello-friend",
      start: "top 25%",
      toggleActions: "play pause resume none",
      // markers: true,
      animation: tlScrollDown.current,
      end: "bottom 10%",
    });
    ScrollTrigger.create({
      trigger: "#digital-partner",
      start: "top 25%",
      // markers: true,
      animation: tlDigitalPartner.current,
    });
    ScrollTrigger.create({
      trigger: "#workflow",
      start: "top 25%",
      // markers: true,
      animation: tlWorkflow.current,
    });
    ScrollTrigger.create({
      trigger: "#auxiliary-teams",
      start: "top 25%",
      // markers: true,
      animation: tlAuxiliaryTeams.current,
    });
    ScrollTrigger.create({
      trigger: "#lets-build",
      start: "top 25%",
      // markers: true,
      animation: tlLetsBuild.current,
    });

    return function cleanupListener() {
      try {
        tlHello.current.kill();
        tlDigitalPartner.current.kill();
        tlWorkflow.current.kill();
        tlAuxiliaryTeams.current.kill();
        tlLetsBuild.current.kill();
        tlMatrix.current.kill();
        tlMatrixBackground.current.kill();
        tlScrollDown.current.kill();
        tlSidebar.current.kill();
        tlBranding.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);
  return (
    <React.Fragment>
      <SEO title="Klove & Sitch Agency" article={true} />
      <Helmet>
        <title>Klove &amp; Sitch Agency</title>
      </Helmet>
      <Container>
        <Layout skipTo="digital-partner">
          <MainContent>
            <Branding getStandAloneTimelines={getStandAloneTimelines} />
            <HelloFriend getStandAloneTimelines={getStandAloneTimelines}>
              <ScrollCue
                getStandAloneTimelines={getStandAloneTimelines}
                scrollTo="digital-partner"
              />
            </HelloFriend>
            <DigitalPartner getStandAloneTimelines={getStandAloneTimelines} />
            <Workflow getStandAloneTimelines={getStandAloneTimelines} />
            <AuxiliaryTeams getStandAloneTimelines={getStandAloneTimelines} />
            <LetsBuild
              getStandAloneTimelines={getStandAloneTimelines}
              gridArea="6/2/7/4"
            />
            <Footer gridArea="7/2/8/4" />
          </MainContent>
          <Sidebar
            getStandAloneTimelines={getStandAloneTimelines}
            userInterface={"hello"}
          />
        </Layout>
        <VisionMatrix
          matrixTimeline={getStandAloneTimelines}
          backgroundTimeline={getStandAloneTimelines}
        />
      </Container>
    </React.Fragment>
  );
};

export default IndexPage;
