import React from "react";
import { useLayoutEffect, useRef } from "react";
import gsap from "gsap";

import { technoOrange, h2Styles } from "../styles/global.module.css";
import {
  workflowStyles,
  workflowH2Styles,
  workflowDescriptionStyles,
  description1Styles,
  description2Styles,
  description3Styles,
  description4Styles,
  description5Styles,
  description6Styles,
  glitchStyles,
  glitchStylesVideo,
  glitchStylesText,
  heptagonStyles,
  polygonStyles,
  listStyles,
  numberStyles,
  titleStyles,
  titlePStyles,
  iconStyles,
  icon0Styles,
  icon1Styles,
  icon2Styles,
  icon3Styles,
  icon4Styles,
  icon5Styles,
  icon6Styles,
} from "../styles/workflow.module.css";

function Workflow({ getStandAloneTimelines }) {
  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined";
  const tlWorkflow = useRef();
  const tlDescription = useRef();

  useLayoutEffect(() => {
    function setOrigin() {
      gsap.set("#heptagon", { transformOrigin: "50% 50%" });
    }
    if (isBrowser) {
      window.addEventListener("resize", setOrigin);
    }
    const icon0 = document.getElementById("icon0");
    const icon1 = document.getElementById("icon1");
    const icon2 = document.getElementById("icon2");
    const icon3 = document.getElementById("icon3");
    const icon4 = document.getElementById("icon4");
    const icon5 = document.getElementById("icon5");
    const icon6 = document.getElementById("icon6");

    gsap.set("#workflow .glitch-video", { display: "none" });
    gsap.set(".description-bg", { display: "none" });
    gsap.set(".description-text", { display: "none" });
    gsap.set("#numbers ul", { display: "none" });

    function loadDescription(id) {
      tlDescription.current = gsap
        .timeline()
        .to(`#${id}-description`, { duration: 0.1, backgroundColor: "#101010" })
        .to(`#${id}-description .glitch-video`, {
          duration: 0.1,
          display: "block",
        })
        .to(`#${id}-description .glitch-video`, {
          duration: 0.1,
          display: "none",
        })
        .to(
          `#${id}-description .description-bg`,
          { duration: 0.1, display: "block" },
          "-=0.1"
        )
        .to(
          `#${id}-description .description-text`,
          { duration: 0.1, display: "block" },
          "-=0.1"
        )
        .to(
          [`#${id} path`, `#${id} line`, `#${id} circle`],
          {
            stroke: "#FC4D0D",
          },
          "-=0.4"
        );
    }

    function changeNumber(number) {
      let numberOffSet = document.getElementById(`number${number}`);
      gsap.to("#numbers ul", { duration: 0.5, top: -numberOffSet.offsetTop });
    }

    function changeTitle(number) {
      let titleOffSet = document.getElementById(`title${number}`);
      gsap.set("#titles ul", { top: -titleOffSet.offsetTop });
    }

    function rotateHeptagon(deg) {
      gsap.to("#heptagon", { duration: 0.3, rotation: deg });
    }

    function removeDescription(id) {
      gsap.killTweensOf(`#${id}-description`);
      gsap.killTweensOf(`#${id}-description .glitch-video`);
      gsap.killTweensOf(`#${id}-description .description-bg`);
      gsap.killTweensOf(`#${id}-description .description-text`);
      gsap.killTweensOf(`#${id} path`);
      gsap.killTweensOf(`#${id} line`);
      gsap.killTweensOf(`#${id} circle`);
      gsap.set(`#${id}-description`, { backgroundColor: "transparent" });
      gsap.set(`#${id}-description .glitch-video`, { display: "none" });
      gsap.set(`#${id}-description .description-bg`, { display: "none" });
      gsap.set(`#${id}-description .description-text`, { display: "none" });
      gsap.set([`#${id} path`, `#${id} line`, `#${id} circle`], {
        stroke: "#ffffff",
      });
    }

    function loader(id, deg, number) {
      loadDescription(id);
      rotateHeptagon(deg);
      changeNumber(number);
      changeTitle(number);
    }

    icon0.addEventListener("mouseenter", icon0AddHover, false);

    function icon0AddHover() {
      rotateHeptagon("0");
      changeNumber("0");
      changeTitle("0");
      gsap.set([`#icon0 path`, `#icon0 line`], {
        stroke: "#FC4D0D",
      });
    }

    icon0.addEventListener("mouseleave", icon0RemoveHover, false);

    function icon0RemoveHover() {
      gsap.set([`#icon0 path`, `#icon0 line`], {
        stroke: "#ffffff",
      });
    }

    const icon1AddHover = function () {
      loader("icon1", "55", "1");
    };

    const icon1RemoveHover = function () {
      removeDescription("icon1");
    };

    const icon2AddHover = function () {
      loader("icon2", "105", "2");
    };

    const icon2RemoveHover = function () {
      removeDescription("icon2");
    };

    const icon3AddHover = function () {
      loader("icon3", "155", "3");
    };

    const icon3RemoveHover = function () {
      removeDescription("icon3");
    };

    const icon4AddHover = function () {
      loader("icon4", "205", "4");
    };

    const icon4RemoveHover = function () {
      removeDescription("icon4");
    };

    const icon5AddHover = function () {
      loader("icon5", "260", "5");
    };

    const icon5RemoveHover = function () {
      removeDescription("icon5");
    };

    const icon6AddHover = function () {
      loader("icon6", "305", "6");
    };

    const icon6RemoveHover = function () {
      removeDescription("icon6");
    };

    icon1.addEventListener("mouseenter", icon1AddHover, false);

    icon1.addEventListener("mouseleave", icon1RemoveHover, false);
    icon2.addEventListener("mouseenter", icon2AddHover, false);
    icon2.addEventListener("mouseleave", icon2RemoveHover, false);
    icon3.addEventListener("mouseenter", icon3AddHover, false);
    icon3.addEventListener("mouseleave", icon3RemoveHover, false);
    icon4.addEventListener("mouseenter", icon4AddHover, false);
    icon4.addEventListener("mouseleave", icon4RemoveHover, false);
    icon5.addEventListener("mouseenter", icon5AddHover, false);
    icon5.addEventListener("mouseleave", icon5RemoveHover, false);
    icon6.addEventListener("mouseenter", icon6AddHover, false);
    icon6.addEventListener("mouseleave", icon6RemoveHover, false);

    tlWorkflow.current = gsap
      .timeline()
      .from("#workflow", { duration: 0.1, opacity: 0 })
      .from("#heptagon", {
        duration: 0.2,
        scale: 0,
        transformOrigin: "50% 50%",
      })
      .to(
        ".icon path",
        {
          stroke: "#FC4D0D",
        },
        "-=0.1"
      )
      .to(
        ".icon line",
        {
          stroke: "#FC4D0D",
        },
        "-=0.1"
      )
      .to(
        ".icon circle",
        {
          stroke: "#FC4D0D",
        },
        "-=0.1"
      )
      .from(
        ".icon",
        {
          duration: 0.7,
          display: "none",
          stagger: { each: 0.1 },
        },
        "-=1"
      )
      .to(
        ".icon path",
        {
          stroke: "#ffffff",
          stagger: { each: 0.1 },
        },
        "-=0.6"
      )
      .to(
        ".icon line",
        {
          stroke: "#ffffff",
          stagger: { each: 0.1 },
        },
        "-=0.6"
      )
      .to(
        ".icon circle",
        {
          stroke: "#ffffff",
          stagger: { each: 0.1 },
        },
        "-=0.6"
      )
      .to("#inner", { fill: "#1A1A1A" }, "-=2")
      .to("#numbers ul", { duration: 0.5, display: "block" }, "-=0.7")
      .from("#titles ul", { duration: 0.5, opacity: 0 }, "-=0.6");

    getStandAloneTimelines(tlWorkflow.current, "tl-workflow");

    return function cleanupListener() {
      if (isBrowser) {
        window.removeEventListener("resize", setOrigin);
      }
      icon0.removeEventListener("mouseenter", icon0AddHover, false);
      icon0.removeEventListener("mouseleave", icon0RemoveHover, false);
      icon1.removeEventListener("mouseenter", icon1AddHover, false);
      icon1.removeEventListener("mouseleave", icon1RemoveHover, false);
      icon2.removeEventListener("mouseenter", icon2AddHover, false);
      icon2.removeEventListener("mouseleave", icon2RemoveHover, false);
      icon3.removeEventListener("mouseenter", icon3AddHover, false);
      icon3.removeEventListener("mouseleave", icon3RemoveHover, false);
      icon4.removeEventListener("mouseenter", icon4AddHover, false);
      icon4.removeEventListener("mouseleave", icon4RemoveHover, false);
      icon5.removeEventListener("mouseenter", icon5AddHover, false);
      icon5.removeEventListener("mouseleave", icon5RemoveHover, false);
      icon6.removeEventListener("mouseenter", icon6AddHover, false);
      icon6.removeEventListener("mouseleave", icon6RemoveHover, false);

      try {
        tlWorkflow.current.kill();
        tlDescription.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  return (
    <section id="workflow" className={`section ${workflowStyles}`}>
      <h2 className={`${h2Styles} ${workflowH2Styles}`}>
        <span className={technoOrange}>Our</span> Workflow
      </h2>
      <div
        id="icon1-description"
        className={`${workflowDescriptionStyles} ${description1Styles}`}
      >
        <svg
          className={`description-bg ${glitchStyles}`}
          width="252"
          height="162"
          viewBox="0 0 252 162"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="250" height="160" fill="#1A1A1A" />
          <rect width="3" height="3" fill="#FC4D0D" />
          <rect x="249" width="3" height="3" fill="#FC4D0D" />
          <rect x="249" y="159" width="3" height="3" fill="#FC4D0D" />
          <rect y="159" width="3" height="3" fill="#FC4D0D" />
        </svg>
        <video
          className={`glitch-video ${glitchStylesVideo} ${glitchStyles}`}
          src="/glitch.mp4"
          autoPlay
          muted
          playsInline
          loop
        ></video>
        <p className={`description-text ${glitchStylesText} ${glitchStyles}`}>
          Every step we take is based on where your business is now and where
          you'd like it to be. The analysis is our one source of truth.
        </p>
      </div>
      <div
        id="icon2-description"
        className={`${workflowDescriptionStyles} ${description2Styles}`}
      >
        <svg
          className={`description-bg ${glitchStyles}`}
          width="252"
          height="162"
          viewBox="0 0 252 162"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="250" height="160" fill="#1A1A1A" />
          <rect width="3" height="3" fill="#FC4D0D" />
          <rect x="249" width="3" height="3" fill="#FC4D0D" />
          <rect x="249" y="159" width="3" height="3" fill="#FC4D0D" />
          <rect y="159" width="3" height="3" fill="#FC4D0D" />
        </svg>
        <video
          className={`glitch-video ${glitchStylesVideo} ${glitchStyles}`}
          src="/glitch.mp4"
          autoPlay
          muted
          playsInline
          loop
        ></video>
        <p className={`description-text ${glitchStylesText} ${glitchStyles}`}>
          We present our high level strategy with all justifications for
          decisions made. This document guides all decisions that follow.
        </p>
      </div>
      <div
        id="icon3-description"
        className={`${workflowDescriptionStyles} ${description3Styles}`}
      >
        <svg
          className={`description-bg ${glitchStyles}`}
          width="252"
          height="162"
          viewBox="0 0 252 162"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="250" height="160" fill="#1A1A1A" />
          <rect width="3" height="3" fill="#FC4D0D" />
          <rect x="249" width="3" height="3" fill="#FC4D0D" />
          <rect x="249" y="159" width="3" height="3" fill="#FC4D0D" />
          <rect y="159" width="3" height="3" fill="#FC4D0D" />
        </svg>
        <video
          className={`glitch-video ${glitchStylesVideo} ${glitchStyles}`}
          src="/glitch.mp4"
          autoPlay
          muted
          playsInline
          loop
        ></video>
        <p className={`description-text ${glitchStylesText} ${glitchStyles}`}>
          Online strategies are often an extension of offline processes. We
          ensure your offline processes are also optimized to support these
          strategies.
        </p>
      </div>
      <div
        id="icon4-description"
        className={`${workflowDescriptionStyles} ${description4Styles}`}
      >
        <svg
          className={`description-bg ${glitchStyles}`}
          width="252"
          height="162"
          viewBox="0 0 252 162"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="250" height="160" fill="#1A1A1A" />
          <rect width="3" height="3" fill="#FC4D0D" />
          <rect x="249" width="3" height="3" fill="#FC4D0D" />
          <rect x="249" y="159" width="3" height="3" fill="#FC4D0D" />
          <rect y="159" width="3" height="3" fill="#FC4D0D" />
        </svg>
        <video
          className={`glitch-video ${glitchStylesVideo} ${glitchStyles}`}
          src="/glitch.mp4"
          autoPlay
          muted
          playsInline
          loop
        ></video>
        <p className={`description-text ${glitchStylesText} ${glitchStyles}`}>
          With all previous steps done and an outline of our first development
          iteration, we hit the ground running. Fast!
        </p>
      </div>
      <div
        id="icon5-description"
        className={`${workflowDescriptionStyles} ${description5Styles}`}
      >
        <svg
          className={`description-bg ${glitchStyles}`}
          width="252"
          height="162"
          viewBox="0 0 252 162"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="250" height="160" fill="#1A1A1A" />
          <rect width="3" height="3" fill="#FC4D0D" />
          <rect x="249" width="3" height="3" fill="#FC4D0D" />
          <rect x="249" y="159" width="3" height="3" fill="#FC4D0D" />
          <rect y="159" width="3" height="3" fill="#FC4D0D" />
        </svg>
        <video
          className={`glitch-video ${glitchStylesVideo} ${glitchStyles}`}
          src="/glitch.mp4"
          autoPlay
          muted
          playsInline
          loop
        ></video>
        <p className={`description-text ${glitchStylesText} ${glitchStyles}`}>
          We're big on tracking everything and really big on reporting what
          we've found. Don't worry, our reports are anything but boring.
        </p>
      </div>
      <div
        id="icon6-description"
        className={`${workflowDescriptionStyles} ${description6Styles}`}
      >
        <svg
          className={`description-bg ${glitchStyles}`}
          width="252"
          height="162"
          viewBox="0 0 252 162"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="250" height="160" fill="#1A1A1A" />
          <rect width="3" height="3" fill="#FC4D0D" />
          <rect x="249" width="3" height="3" fill="#FC4D0D" />
          <rect x="249" y="159" width="3" height="3" fill="#FC4D0D" />
          <rect y="159" width="3" height="3" fill="#FC4D0D" />
        </svg>
        <video
          className={`glitch-video ${glitchStylesVideo} ${glitchStyles}`}
          src="/glitch.mp4"
          autoPlay
          muted
          playsInline
          loop
        ></video>
        <p className={`description-text ${glitchStylesText} ${glitchStyles}`}>
          Great software is less of a product and more of a process. Our
          findings further improve the current build.
        </p>
      </div>
      <div className={heptagonStyles}>
        <svg
          id="heptagon"
          className={polygonStyles}
          width="360"
          height="360"
          viewBox="0 0 360 360"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="outer"
            d="M180 0L320.73 67.7718L355.487 220.054L258.099 342.174H101.901L4.51297 220.054L39.2703 67.7718L180 0Z"
            fill="#FC4D0D"
          />
          <path
            id="inner"
            d="M178.5 35.5L317.933 69.8518L352 219.261L256.547 339.078H103.453L8 219.261L42.0667 69.8518L178.5 35.5Z"
            fill="#FC4D0D"
          />
        </svg>
        <div id="numbers" className={`${technoOrange} ${numberStyles}`}>
          <ul className={listStyles}>
            <li id="number0">[0]</li>
            <li id="number1">[1]</li>
            <li id="number2">[2]</li>
            <li id="number3">[3]</li>
            <li id="number4">[4]</li>
            <li id="number5">[5]</li>
            <li id="number6">[6]</li>
          </ul>
        </div>
        <div id="titles" className={titleStyles}>
          <ul className={listStyles}>
            <li id="title0">
              Sign NDA
              <p className={titlePStyles}>Your secrets are our secrets</p>
            </li>
            <li id="title1">Business Analysis</li>
            <li id="title2">Present Strategy</li>
            <li id="title3">
              Offline <span>Prep</span>
            </li>
            <li id="title4">Execute Strategy</li>
            <li id="title5">
              Report
              <br />
              Findings
            </li>
            <li id="title6">
              Repeat
              <p className={titlePStyles}>With our new findings</p>
            </li>
          </ul>
        </div>
        <svg
          className={`icon ${iconStyles} ${icon0Styles}`}
          id="icon0"
          width="51"
          height="52"
          viewBox="0 0 51 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10.1836 40.8148L42.2923 8.70609L49.9984 1" stroke="white" />
          <line y1="51.426" x2="50" y2="51.426" stroke="white" />
        </svg>
        <svg
          id="icon1"
          className={`icon ${iconStyles} ${icon1Styles}`}
          width="52"
          height="25"
          viewBox="0 0 52 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 23.7586L20.3103 7.2069L29.9655 16.8621L51 1"
            stroke="white"
          />
        </svg>
        <svg
          id="icon2"
          className={`icon ${iconStyles} ${icon2Styles}`}
          width="15"
          height="56"
          viewBox="0 0 15 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.49459 55.7613L13.6673 31.6769L1.11165 26.3104L8.00242 0.882612"
            stroke="white"
          />
        </svg>
        <svg
          id="icon3"
          className={`icon ${iconStyles} ${icon3Styles}`}
          width="52"
          height="33"
          viewBox="0 0 52 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 14.6454L16.9091 32.0811L51 1" stroke="white" />
        </svg>
        <svg
          id="icon4"
          className={`icon ${iconStyles} ${icon4Styles}`}
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="25" cy="25" r="24.5" stroke="white" />
          <rect x="20" y="20" width="10" height="10" rx="5" fill="#FC4D0D" />
        </svg>
        <svg
          id="icon5"
          className={`icon ${iconStyles} ${icon5Styles}`}
          width="50"
          height="38"
          viewBox="0 0 50 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="0.5" x2="50" y2="0.5" stroke="white" />
          <line y1="12.5371" x2="50" y2="12.5371" stroke="white" />
          <line y1="24.574" x2="50" y2="24.574" stroke="white" />
          <line y1="36.6111" x2="50" y2="36.6111" stroke="white" />
        </svg>
        <svg
          id="icon6"
          className={`icon ${iconStyles} ${icon6Styles}`}
          width="58"
          height="50"
          viewBox="0 0 58 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="25" cy="25" r="24.5" stroke="white" />
          <path
            d="M57.7577 35.6935L43.1953 41.0463L39.2485 26.1577"
            stroke="white"
          />
        </svg>
      </div>
    </section>
  );
}

export default Workflow;
