import React, { useLayoutEffect, useRef } from "react";
import gsap from "gsap";

// GSAP Pluggins
import { SplitText } from "gsap/SplitText";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";

import {
  technoOrange,
  h2Styles,
  matrixBlack,
  hiddenStyles,
} from "../styles/global.module.css";
import {
  helloStyles,
  helloText,
  helloAestheticStyles,
  helloTextBgStyles,
  firstChild,
  lastChild,
  firstLetter,
} from "../styles/hello-friend.module.css";

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrambleTextPlugin);

function HelloFriend({ getStandAloneTimelines, children }) {
  //TODO: check performance of refs on parent element.
  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined";

  const tlHello = useRef();
  let offsetTop = 210;
  let offsetLeft = 800;
  let rowHeight = 12;
  let cursorTop = 300;

  useLayoutEffect(() => {
    function removeTitle() {
      let el = document.getElementById("text-1");
      if (el) {
        el.remove();
      }
    }

    window.addEventListener("resize", removeTitle);

    (function mediaQueryWatcher() {
      const mediaQueries = [
        "(min-width: 1600px)",
        "(min-width: 1920px)",
        "(min-width: 2200px)",
        "(max-width: 1325px)",
        "(max-width: 1175px)",
        "(max-width: 1105px)",
        "(max-width: 965px)",
        "(max-width: 850px)",
        "(max-width: 745px)",
        "(max-width: 675px)",
        "(max-width: 575px)",
        "(max-width: 500px)",
      ];
      let mql;
      mediaQueries.forEach((query) => {
        if (isBrowser) {
          mql = window.matchMedia(query);
          if (mql.matches) {
            switch (query) {
              case "(min-width: 1600px)":
                offsetTop = 300;
                cursorTop = 380;
                rowHeight = 17;
                break;
              case "(min-width: 1920px)":
                offsetTop = 300;
                offsetLeft = 520;
                rowHeight = 17;
                cursorTop = 380;
                break;
              case "(min-width: 2200px)":
                offsetTop = 380;
                offsetLeft = 520;
                rowHeight = 23;
                cursorTop = 475;
                break;
              case "(max-width: 1325px)":
                offsetTop = 210;
                offsetLeft = 680;
                break;
              case "(max-width: 1175px)":
                offsetLeft = 630;
                break;
              case "(max-width: 1105px)":
                offsetTop = 225;
                offsetLeft = 540;
                break;
              case "(max-width: 965px)":
                offsetTop = 240;
                offsetLeft = 440;
                break;
              case "(max-width: 850px)":
                offsetLeft = 350;
                break;
              case "(max-width: 745px)":
                offsetTop = 255;
                offsetLeft = 300;
                break;
              case "(max-width: 675px)":
                offsetLeft = 250;
                break;
              case "(max-width: 575px)":
                offsetLeft = 210;
                break;
              case "(max-width: 500px)":
                offsetTop = 275;
                offsetLeft = 150;
                break;
              default:
                offsetTop = 210;
                offsetLeft = 800;
                break;
            }
          }
        }
      });
    })();

    function removeElement(element) {
      if (document.getElementById(element) != null) {
        let el = document.querySelector(`#${element}`);
        el.remove();
      }
      return;
    }

    function moveCursor(element) {
      let currentLetter = this.targets()[0];
      let offset = currentLetter.getBoundingClientRect();
      let currentLetterWidth = parseInt(currentLetter.style.width, 10);
      let cursorPos = offset.left + currentLetterWidth;
      let cursor = document.getElementById(element).firstElementChild;
      cursor.setAttribute(
        "style",
        `position: absolute; display: block; left: ${cursorPos}px; top: ${cursorTop}px; width: 18px; height: 83px; opacity: 1;`
      );
    }

    const helloWorld1 = new SplitText("#text-1", {
      type: "chars",
      position: "absolute",
    });

    const helloWorld2 = new SplitText("#text-3", {
      type: "chars",
      position: "absolute",
    });

    const chars1 = helloWorld1.chars;
    const chars2 = helloWorld2.chars;

    tlHello.current = gsap
      .timeline()
      .to("#text-1", { duration: 0.1, opacity: 1 })
      .from(chars1, {
        duration: 0.2,
        opacity: 0,
        stagger: {
          each: 0.03,
          onStart: moveCursor,
          onStartParams: ["text-1"],
        },
      })
      .to(
        "#text-1 div:first-child",
        { duration: 0.3, opacity: 0, repeat: 2 },
        "-=0.5"
      )
      .to("#text-1 div", { duration: 0.1, top: offsetTop })
      .to("#text-3", { duration: 0.1, opacity: 1 })
      .from(chars2, {
        duration: 0.2,
        opacity: 0,
        stagger: {
          each: 0.03,
          onStart: moveCursor,
          onStartParams: ["text-3"],
        },
      })
      .to(
        "#text-3 div:first-child",
        { duration: 0.3, opacity: 0, repeat: 2 },
        "-=0.5"
      )
      .to("#text-3", {
        duration: 0.1,
        display: "none",
        onComplete: removeElement,
        onCompleteParams: ["text-3"],
      })
      .to("#text-2", { duration: 0.1, display: "block", opacity: 1 })
      .to("#scramble", {
        duration: 1,
        opacity: 1,
        scrambleText: { text: "Brands", chars: "!/&%{}]?#()" },
      })
      .to("#scramble", {
        duration: 1,
        scrambleText: { text: "Audiences", chars: "!/&%{}]?#()" },
      })
      .to("#scramble", {
        duration: 1,
        scrambleText: { text: "Strategies", chars: "!/&%{}]?#()" },
      })
      .to(".aesthetics", {
        duration: 0.2,
        opacity: 1,
        repeat: 3,
      })
      .from(".aesthetics", { duration: 1, marginLeft: offsetLeft })
      .to(".black-bg", { duration: 1, opacity: 1 }, "-=1")
      .to("#hello-friend", {
        duration: 0.5,
        gridTemplateRows: `${rowHeight}em 8em 13em`,
      })
      .to("#skip-to-content", { duration: 0.1, display: "none" });

    getStandAloneTimelines(tlHello.current, "tl-hello-friend");

    return function cleanupListener() {
      if (isBrowser) {
        window.removeEventListener("resize", removeTitle);
      }
      try {
        tlHello.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  return (
    <section id="hello-friend" className={helloStyles}>
      <h2 id="text-1" className={`${helloText} ${firstChild} ${h2Styles}`}>
        |We Don't Build Websites
      </h2>
      <h2
        id="text-3"
        className={`${helloText} ${firstChild} ${lastChild} ${h2Styles}`}
      >
        |We Build Experiences
      </h2>
      <h2
        id="text-2"
        className={`${helloText} ${h2Styles} ${firstLetter} ${hiddenStyles}`}
      >
        |We Build{" "}
        <span id="scramble" className={technoOrange}>
          Experiences
        </span>
      </h2>
      <div className={`aesthetics ${technoOrange} ${helloAestheticStyles}`}>
        <svg
          width="122"
          height="150"
          viewBox="0 0 122 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="aesthetics-slash">
            <g id="slashes">
              <g id="1">
                <path
                  d="M4.42554 112.64L44.5269 36.6357H52.1343L11.9402 112.64H4.42554Z"
                  fill="#FC4D0D"
                />
                <path
                  d="M4.42554 112.64L2.21443 111.474L0.279859 115.14H4.42554V112.64ZM44.5269 36.6357V34.1357H43.0193L42.3157 35.4691L44.5269 36.6357ZM52.1343 36.6357L54.3443 37.8045L56.2844 34.1357H52.1343V36.6357ZM11.9402 112.64V115.14H13.4462L14.1502 113.809L11.9402 112.64ZM6.63665 113.807L46.738 37.8024L42.3157 35.4691L2.21443 111.474L6.63665 113.807ZM44.5269 39.1357H52.1343V34.1357H44.5269V39.1357ZM49.9243 35.467L9.73019 111.472L14.1502 113.809L54.3443 37.8045L49.9243 35.467ZM11.9402 110.14H4.42554V115.14H11.9402V110.14Z"
                  fill="black"
                />
              </g>
              <g id="2">
                <path
                  d="M27.4255 112.64L67.5269 36.6357H75.1343L34.9402 112.64H27.4255Z"
                  fill="#FC4D0D"
                />
                <path
                  d="M27.4255 112.64L25.2144 111.474L23.2799 115.14H27.4255V112.64ZM67.5269 36.6357V34.1357H66.0193L65.3157 35.4691L67.5269 36.6357ZM75.1343 36.6357L77.3443 37.8045L79.2844 34.1357H75.1343V36.6357ZM34.9402 112.64V115.14H36.4462L37.1502 113.809L34.9402 112.64ZM29.6366 113.807L69.738 37.8024L65.3157 35.4691L25.2144 111.474L29.6366 113.807ZM67.5269 39.1357H75.1343V34.1357H67.5269V39.1357ZM72.9243 35.467L32.7302 111.472L37.1502 113.809L77.3443 37.8045L72.9243 35.467ZM34.9402 110.14H27.4255V115.14H34.9402V110.14Z"
                  fill="black"
                />
              </g>
              <g id="3">
                <path
                  d="M50.4255 112.64L90.5269 36.6357H98.1343L57.9402 112.64H50.4255Z"
                  fill="#FC4D0D"
                />
                <path
                  d="M50.4255 112.64L48.2144 111.474L46.2799 115.14H50.4255V112.64ZM90.5269 36.6357V34.1357H89.0193L88.3157 35.4691L90.5269 36.6357ZM98.1343 36.6357L100.344 37.8045L102.284 34.1357H98.1343V36.6357ZM57.9402 112.64V115.14H59.4462L60.1502 113.809L57.9402 112.64ZM52.6366 113.807L92.738 37.8024L88.3157 35.4691L48.2144 111.474L52.6366 113.807ZM90.5269 39.1357H98.1343V34.1357H90.5269V39.1357ZM95.9243 35.467L55.7302 111.472L60.1502 113.809L100.344 37.8045L95.9243 35.467ZM57.9402 110.14H50.4255V115.14H57.9402V110.14Z"
                  fill="black"
                />
              </g>
            </g>
          </g>
        </svg>
        <div className={`black-bg ${helloTextBgStyles} ${matrixBlack}`}></div>
      </div>
      {children}
    </section>
  );
}

export default HelloFriend;
