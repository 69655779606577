import React, { useLayoutEffect, useRef } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import gsap from "gsap";

import {
  technoOrange,
  h2Styles,
  visionGrey,
  rightAlignStyles,
  pStyles,
  taglineStyles,
  glitch,
  relative,
  breakStyles,
} from "../styles/global.module.css";
import { digitalPartnerStyles } from "../styles/digital-partner.module.css";

function DigitalPartner({ getStandAloneTimelines }) {
  const tlDigitalPartner = useRef();
  const breakpoints = useBreakpoint();
  tlDigitalPartner.current = gsap.timeline();

  useLayoutEffect(() => {
    let parentElement = document.getElementById("digital-partner");
    let children = parentElement.childNodes;
    tlDigitalPartner.current.from(children, {
      duration: 1,
      opacity: 0,
      stagger: { each: 0.2 },
    });
    getStandAloneTimelines(tlDigitalPartner.current, "tl-digital-partner");

    return function cleanupListener() {
      try {
        tlDigitalPartner.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);
  return breakpoints.xxs ? (
    <section
      id="digital-partner"
      className={`section ${rightAlignStyles} ${digitalPartnerStyles}`}
    >
      <h2
        data-heading="What's A Digital Partner Agency?"
        className={`${glitch} ${relative} ${h2Styles} ${rightAlignStyles}`}
      >
        What's A <span className={technoOrange}>Digital</span> Partner Agency?
      </h2>
      <p className={`${visionGrey} ${taglineStyles} ${rightAlignStyles}`}>
        Sometimes you just need a{" "}
        <span className={breakStyles}>
          website, and that's ok
          <span className={technoOrange}>.</span>
        </span>
      </p>
      <p className={`${visionGrey} ${taglineStyles} ${rightAlignStyles}`}>
        We're here if you need more<span className={technoOrange}>.</span>
      </p>
      <p className={`${pStyles} ${rightAlignStyles}`}>
        Let's face it, transforming your business to thrive in the digital age
        is no small task.
      </p>
      <p className={`${pStyles} ${rightAlignStyles}`}>
        The reality is that the bureaucracy of the corproate environment isn't
        equipped to respond quickly enough to changes in the current digital
        space. That is, not without some{" "}
        <span className={technoOrange}>expert assistance</span>. As a
        <span className={technoOrange}> Digital Partner</span>, we bring digital
        harmony to your business. Gone are the days of approaching digital on a
        project by project basis. Digital strategies must be cohesive and must
        be aligned with long term business objectives for real return on
        investment. We take the time to understand where you would like to go
        and build the digital road to get you there, one phase at a time.
      </p>
    </section>
  ) : (
    <section
      id="digital-partner"
      className={`section ${rightAlignStyles} ${digitalPartnerStyles}`}
    >
      <h2
        data-heading="What's A Digital Partner Agency?"
        className={`${glitch} ${relative} ${h2Styles} ${rightAlignStyles}`}
      >
        What's A <span className={technoOrange}>Digital</span> Partner Agency?
      </h2>
      <p className={`${visionGrey} ${taglineStyles} ${rightAlignStyles}`}>
        Sometimes you just need a{" "}
        <span className={breakStyles}>
          website, and that's ok
          <span className={technoOrange}>.</span>
        </span>
      </p>
      <p className={`${visionGrey} ${taglineStyles} ${rightAlignStyles}`}>
        We're here if you need more<span className={technoOrange}>.</span>
      </p>
      <p className={`${pStyles} ${rightAlignStyles}`}>
        Let's face it, transforming your business to thrive in the digital age
        is no small task. We're a long way from where we started. In today's
        world, success means having access to the right technical resources,
        business savvy personnel who understand how your particular business
        model translates to the digital space and marketing personnel who
        understand the specifics of marketing to your target audience both
        online and offline. To make that a little more challenging, these
        persons must all speak the digital language and be ready to pivot an
        entire strategy when the market demands it.
      </p>
      <p className={`${pStyles} ${rightAlignStyles}`}>
        The reality is that the bureaucracy of the corporate environment isn't
        equipped to respond quickly enough to changes in the current digital
        space. That is, not without some{" "}
        <span className={technoOrange}>expert assistance</span>. As a
        <span className={technoOrange}> Digital Partner</span>, we bring digital
        harmony to your business. Gone are the days of approaching digital on a
        project by project basis. Digital strategies must be cohesive and must
        be aligned with long term business objectives for real return on
        investment. We take the time to understand where you would like to go
        and build the digital road to get you there, one phase at a time.
      </p>
    </section>
  );
}

export default DigitalPartner;
